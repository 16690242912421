import React, { Fragment, useContext, useState, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import DataContext from "../../context/Data/DataContext"
import FilterSelect from "./FilterSelect"
import FilterCheckbox from "./FilterCheckBox"
import FilterTag from "./FilterTag"
import Separator from "./Separator"
import FilterToggle from "./FilterToggle"
import StateContext from "../../context/state/StateContext"
import Icon from "../../assets/icons/close-alt.svg"
import { getTagCategoryProduct as getTagCategory } from "../../helpers/DrupalHelper"
import Slideout from "./Slideout"
import ProductsFilter from "./ProductsFilter"

const sortOptions = [
  { label: "Title (ascending)", value: { title: "desc" } },
  { label: "Title (descending)", value: { title: "asc" } },
  { label: "Price: Low to High", value: { field_product_price: "asc" } },
  { label: "Price: High to Low  ", value: { field_product_price: "desc" } },
]
export default function ProductsSideBarFilter(props) {
  //   const [open, setOpen] = useState(true)
  const { tagsAsOptions: tags } = useContext(DataContext).data
  const { state } = useContext(StateContext)

  const [categories, setCategories] = useState([])
  const [pType, setPType] = useState([])
  useEffect(async () => {
    let catData = await getTagCategory(state.token)
    // console.log(catData)
    let cData = catData.map(v => {
      return { value: v.id, label: v.title }
    })
    setCategories([...cData])
    // let ptData = await getTagProductType(state.token)
    // // console.log(ptData)
    // let pData = ptData.map(v => {
    //   return { value: v.id, label: v.title }
    // })
    // setPType([...pData])
  }, [])
  return (
    <Slideout
      title="Product Filter"
      onClose={props.setOpen}
      open={props.open}
      fromPage={props.fromPage ? true : false}
      setOpen={props.setOpen}
      toggleSidebar={props.toggleSidebar}
    >
      <ProductsFilter {...props} />
    </Slideout>
  )
  // return (
  //   <Transition.Root show={open} as={Fragment}>
  //     <Dialog
  //       as="div"
  //       className="fixed inset-0 overflow-hidden"
  //       onClose={setOpen}
  //     >
  //       <div className="absolute inset-0 overflow-hidden">
  //         <Transition.Child
  //           as={Fragment}
  //           enter="ease-in-out duration-300"
  //           enterFrom="opacity-0"
  //           enterTo="opacity-100"
  //           leave="ease-in-out duration-300"
  //           leaveFrom="opacity-100"
  //           leaveTo="opacity-0"
  //         >
  //           <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  //         </Transition.Child>
  //
  //         <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
  //           <Transition.Child
  //             as={Fragment}
  //             enter="transform transition ease-in-out duration-300"
  //             enterFrom="-translate-x-full"
  //             enterTo="translate-x-0"
  //             leave="transform transition ease-in-out duration-300"
  //             leaveFrom="translate-x-0"
  //             leaveTo="-translate-x-full"
  //           >
  //             <div className="pointer-events-auto w-screen max-w-md lg:max-w-[330px]">
  //               <div className="flex h-full flex-col overflow-y-scroll bg-white py-[10px] shadow-xl">
  //                 <div className="">
  //                   <div className="border-b border-[#EBEBEB] mb-10 pb-[10px]">
  //                     <div className="flex items-start justify-between items-center px-[30px]">
  //                       <Dialog.Title className="text-strong-20">
  //                         Product Filter
  //                       </Dialog.Title>
  //                       <button
  //                         type="button"
  //                         className="flex justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
  //                         onClick={() => setOpen(false)}
  //                       >
  //                         <span className="sr-only">Close</span>
  //                         <Icon className="w-3 h-3" />
  //                       </button>
  //                       {/*  <div className="ml-3 flex h-7 items-center">
  //                       <button
  //                         type="button"
  //                         className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
  //                         onClick={() => setOpen(false)}
  //                       >
  //                         <span className="sr-only">Close panel</span>
  //                         <XIcon className="h-6 w-6" aria-hidden="true" />
  //                       </button>
  //                     </div>*/}
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className="relative flex-1 px-[30px]">
  //                   {/* Replace with your content */}
  //                   <form className="">
  //                     {categories.length && (
  //                       <FilterCheckbox
  //                         title={"Category"}
  //                         options={categories}
  //                         onChange={v => {
  //                           setFilterBody(body => {
  //                             return { ...body, categories: v }
  //                           })
  //                         }}
  //                       />
  //                     )}
  //                     <Separator className="my-[30px]" />
  //                     {tags.length && (
  //                       <FilterTag
  //                         title={"Tags"}
  //                         options={tags}
  //                         onChange={v => {
  //                           setFilterBody(body => {
  //                             return { ...body, tags: v }
  //                           })
  //                         }}
  //                       />
  //                     )}
  //                     <Separator className="my-[30px]" />
  //
  //                     {pType.length && (
  //                       <FilterCheckbox
  //                         title={"Product Type"}
  //                         options={pType}
  //                         onChange={v => {
  //                           setFilterBody(body => {
  //                             return { ...body, product_type: v }
  //                           })
  //                         }}
  //                       />
  //                     )}
  //                     <Separator className="my-[30px]" />
  //                     <FilterToggle
  //                       title={"Asset Type"}
  //                       options={[
  //                         { value: "digital", label: "Digital" },
  //                         { value: "physical", label: "Physical" },
  //                       ]}
  //                       onChange={v => {
  //                         setFilterBody(body => {
  //                           return {
  //                             ...body,
  //                             asset_type: v.length > 1 ? v : v[0],
  //                           }
  //                         })
  //                       }}
  //                     />
  //                     <Separator className="my-[30px]" />
  //                   </form>
  //                   {/* /End replace */}
  //                 </div>
  //               </div>
  //             </div>
  //           </Transition.Child>
  //         </div>
  //       </div>
  //     </Dialog>
  //   </Transition.Root>
  // )
}
