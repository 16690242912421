/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useRef } from "react"
// import { useStaticQuery, graphql, Link } from "gatsby"
import {
  // Fragment,
  useState,
} from "react"
// import {
//   Dialog,
//   Disclosure,
//   Menu,
//   Popover,
//   Tab,
//   Transition,
// } from "@headlessui/react"
import PageSubtitle from "../components/PageSubtitle"
// import {
//   MenuIcon,
//   SearchIcon,
//   ShoppingBagIcon,
//   XIcon,
// } from "@heroicons/react/outline"
// import {
//   ChevronDownIcon,
//   FilterIcon,
//   MinusSmIcon,
//   PlusSmIcon,
//   ViewGridIcon,
//   ViewListIcon,
// } from "@heroicons/react/solid"

// import Header from "../components/Commons/Header/Header"
// import Footer from "../components/Commons/Footer/Footer"
import BannerSlider from "../components/Commons/BannerSlider"
// import MetaDownload from "../components/Elements/MetaDownload"
import LayoutPage from "../components/Layout/Pages"
import {
  // getOnSiteMarketingProductsHero,
  getOnSiteMarketingCustomLocation,
} from "../helpers/DrupalHelper"
import ProductGridCard from "../components/Cards/ProductGridCard"
// import FilterSelect from "../components/Elements/FilterSelect"
// import { usePagination } from "../utils"
import Pagination from "../components/Pagination"
// import FilterCheckbox from "../components/Elements/FilterCheckBox"
// import FilterTag from "../components/Elements/FilterTag"
// import Separator from "../components/Elements/Separator"
import { viewOptions } from "../data"
// import FilterToggle from "../components/Elements/FilterToggle"
import {
  getProductListing,
  // getTagCategory,
  getTagCategoryProduct,
  // getTagProductType,
  getTagsProduct,
} from "../helpers/DrupalHelper"
import StateContext from "../context/state/StateContext"
import Listing from "../components/Elements/Listing"
import ProductsSideBarFilter from "../components/Elements/ProductsSideBarFilter"
import DataContext from "../context/Data/DataContext"
import { parse } from "query-string"
import ProductListCard from "../components/Cards/ProductListCard"
import ProductsFilter from "../components/Elements/ProductsFilter"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
// import AccessDenied from "../components/AccessDenied"

const sortOptions = [
  { label: "Most recent", value: { created: "desc" } },
  { label: "Title (ascending)", value: { title: "asc" } },
  { label: "Title (descending)", value: { title: "desc" } },
  // { label: "Price: Low to High", value: { field_product_price: "asc" } },
  // { label: "Price: High to Low  ", value: { field_product_price: "desc" } },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function ProductListing({ data, location, pageContext }) {
  // console.log(data, pageContext)
  const { userType } = useContext(AuthenticationContext)
  const { state } = useContext(StateContext)
  const { token } = state

  // const allProducts = data.allProductsListing.nodes

  // const [gridView, setGridView] = useState(true)
  const [pageNumber, setPageNumber] = useState(1)
  const [allItems, setAllItems] = useState([])
  const [totalPage, setTotalPage] = useState(1)
  const [perPage, setPerPage] = useState(12)
  const [total, setTotal] = useState(1)
  // const [complete, setComplete] = useState(false)
  const [hero, setHero] = useState([])
  const [loading, setLoading] = useState(true)
  const [adCode, setAdCode] = useState([])
  const { entityItemsNumber } = useContext(DataContext).data

  useEffect(() => {
    if (!userType.brandManager && !userType.brandEditor) {
      getOnSiteMarketingCustomLocation(token, "21").then(res => {
        res.items?.length && setHero(res.items[0]["items"])
      })
      getOnSiteMarketingCustomLocation(token, "11,12,13,14").then(res => {
        setAdCode(res.items)
      })
    }
  }, [])

  // useEffect(() => {
  //   let pages = Math.ceil(entityItemsNumber.products / perPage)
  //   setTotal(entityItemsNumber.products)
  //   // console.log(pages, entityItemsNumber)
  //   if (pages > 1) setTotalPage(pages)
  // }, [])

  useEffect(() => {
    let pages = Math.ceil(total / perPage)

    if (pages > 1) setTotalPage(pages)
  }, [perPage])

  const [tags, setTags] = useState([])
  const [categories, setCategories] = useState([])
  // const [pType, setPType] = useState([])

  useEffect(async () => {
    let tagData = await getTagsProduct(state.token)
    // console.log(tagData)
    let tData = tagData.map(v => {
      return { value: v.id, label: v.title }
    })
    setTags([...tData])
    let catData = await getTagCategoryProduct(state.token)
    // console.log(catData)
    let cData = catData.map(v => {
      return { value: v.id, label: v.title }
    })
    setCategories([...cData])
    // let ptData = await getTagProductType(state.token)
    // // console.log(ptData)
    // let pData = ptData.map(v => {
    //   return { value: v.id, label: v.title }
    // })
    // setPType([...pData])
  }, [])
  useEffect(() => {
    let pages = Math.ceil(entityItemsNumber.products / perPage)

    // console.log(pages)
    if (pages > 1) setTotalPage(pages)
  }, [perPage])
  // const items = usePagination(allItems, perPage, pageNumber)
  // let resultshowing = perPage * pageNumber

  const Card = ProductGridCard

  useEffect(() => {
    let pages = Math.ceil(total / perPage)

    setTotalPage(pages)
  }, [total])

  // console.log(gridView)
  const [filterOpen, setFilterOpen] = useState(false)

  let firstBody = {
    featured: null,
    asset_type: ["digital", "physical"],
    categories: [],
    tags: [],
    // product_type: [],
    sort: [],
    offset: "0",
    item_per_page: "12",
  }
  const [filterBody, setFilterBody] = useState(firstBody)
  const callReset = async () => {
    setLoading(true)
    setPageNumber(1)
    setFilterBody(firstBody)

    // setAllItems(allProducts)
    setLoading(false)
  }
  // console.log(filterBody)
  // useEffect(() => {
  //   if (JSON.stringify(filterBody) === JSON.stringify(firstBody)
  //     && allItems !== []
  //   ) {
  //     // setAllItems([...serverData.results])
  //     // setTotal(serverData.total)
  //   }
  // }, [filterBody])
  const onPageChange = async v => {
    setLoading(true)
    setPageNumber(v)

    let reqBody = {
      ...filterBody,
      offset: ((parseInt(v) - 1) * perPage).toString(),
    }
    setFilterBody(reqBody)
    let newPageData = await getProductListing(state.token, reqBody)
    // setAllItems(newPageData)
    setAllItems([...newPageData.results])
    setTotal(newPageData.total)
    setLoading(false)
  }
  useEffect(async () => {
    if (location.search) {
      let { p } = parse(location.search)

      if (p) onPageChange(parseInt(p))
    } else {
      let body = {
        featured: null,
        asset_type: ["digital", "physical"],
        categories: [],
        tags: [],
        // product_type: [],
        sort: [{ created: "desc" }],
        offset: "0",
        item_per_page: "12",
      }
      let filteredAssets = await getProductListing(state.token, body)
      // console.log(filteredAssets)
      setAllItems(filteredAssets.results)
      setTotal(filteredAssets.total)
      // setAllItems([...allProducts])
    }
  }, [])

  const onFilterBodyChange = async v => {
    if (JSON.stringify(firstBody) !== JSON.stringify(v)) {
      setLoading(true)
      setPageNumber(1)

      let filteredAssets = await getProductListing(state.token, {
        ...v,
        offset: "0",
      })

      setAllItems(filteredAssets.results)
      setFilterBody(v)
      setTotal(filteredAssets.total)
      setLoading(false)
    }
  }

  const [showSideBar, setShowSideBar] = useState(false)
  const scrollRef = useRef()
  useEffect(() => {
    if (!window.PressedBack) {
      window.listStateProduct = {
        pageNumber,
        allItems,
        totalPage,
        perPage,
        filterBody,
        filterOpen,
        scrollPosition: window.pageYOffset,
        showSideBar,
      }
    }
  })
  useEffect(async () => {
    console.log(window.PressedBack, "back")
    if (window.PressedBack && window.listStateProduct) {
      // dispatch()
      setLoading(true)

      let {
        pageNumber,
        allItems,
        totalPage,
        perPage,
        filterBody,
        filterOpen,
        showSideBar,
        scrollPosition,
      } = window.listStateProduct
      setAllItems(allItems)
      setPageNumber(pageNumber)
      setTotalPage(totalPage)
      setPerPage(perPage)
      setFilterBody(filterBody)
      setFilterOpen(filterOpen)
      setShowSideBar(showSideBar)
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      })
      window.removeEventListener("popstate", () => {
        console.log("event removed")
      })
      window.PressedBack = false
      setLoading(false)
    } else {
      if (location.search) {
        console.log(location, "onPageChange")

        let { p } = parse(location.search)
        console.log(p, parse(location.search))
        if (p) onPageChange(parseInt(p))
      } else setLoading(false)
    }
  }, [])
  // if (userType.brandManager) return <AccessDenied />
  return (
    <div className="bg-white">
      <LayoutPage metaTitle={"Products"}>
        <div className="pt-[20px]">
          <BannerSlider items={hero} />
          <div>
            {/* Mobile filter dialog */}

            <main className="container">
              <section
                aria-labelledby="products-heading"
                className="pt-1 pb-24"
              >
                <PageSubtitle
                  title="Product information to help you discover and list items"
                  marginTop={
                    userType.brandManager
                      ? "20px"
                      : userType.brandEditor && "60px"
                  }
                />
                <div
                  className={classNames(
                    "flex",
                    showSideBar ? "justify-between" : "w-full"
                  )}
                >
                  {/* Filters */}
                  <ProductsFilter
                    open={filterOpen}
                    setOpen={setFilterOpen}
                    setShowSideBar={setShowSideBar}
                    setFilterBody={setFilterBody}
                    onFilterBodyChange={onFilterBodyChange}
                    fromPage={true}
                    showSideBar={showSideBar}
                    toggleSidebar={() => {
                      setShowSideBar(true)
                      setFilterOpen(false)
                    }}
                    className={classNames(
                      "min-w-[20%] hidden pr-10 ",
                      !showSideBar ? "" : "lg:block"
                    )}
                    filterBody={filterBody}
                  />

                  {/* Product grid */}
                  <div
                    className={classNames(
                      showSideBar ? "min-w-[80%]" : "w-full"
                    )}
                  >
                    <Listing
                      pageNumber={pageNumber}
                      large={showSideBar ? false : true}
                      data={
                        loading
                          ? Array(20)
                              .fill(1)
                              .map(v => ({
                                id: "",
                                title: "",
                                bundle: "default",
                                description: "",
                                cover: [""],
                                tags: [],
                                category: [],
                                type: "digital",
                                brand: {
                                  name: "",
                                  logo: "",
                                },
                              }))
                          : allItems
                      }
                      filterBody={filterBody}
                      viewOptions={viewOptions}
                      listRef={scrollRef}
                      callReset={callReset}
                      viewOptionsCallback={v => {
                        setFilterBody(body => {
                          onFilterBodyChange({
                            ...body,
                            item_per_page: v.toString(),
                          })
                          return {
                            ...body,
                            item_per_page: v.toString(),
                          }
                        })
                      }}
                      sortOptions={sortOptions}
                      sortOptionsCallback={v => {
                        // if (v.hasOwnProperty("title")) {
                        //   setFilterBody(body => {
                        //     onFilterBodyChange({
                        //       ...body,
                        //       sort: [
                        //         {
                        //           ...v,
                        //           field_product_price: "asc",
                        //         },
                        //       ],
                        //     })
                        //     return {
                        //       ...body,
                        //       sort: [
                        //         {
                        //           ...v,
                        //           field_product_price: "asc",
                        //         },
                        //       ],
                        //     }
                        //   })
                        // } else {
                        setFilterBody(body => {
                          onFilterBodyChange({
                            ...body,
                            sort: [
                              {
                                ...v,
                              },
                            ],
                          })
                          return {
                            ...body,
                            sort: [
                              {
                                ...v,
                              },
                            ],
                          }
                        })
                      }}
                      openFilters={() => setFilterOpen(true)}
                      component={Card}
                      componentList={ProductListCard}
                      showSideBar={showSideBar}
                      forceTray={true}
                      adCode={adCode}
                      loading={loading}
                      total={total}
                      productPage
                    />

                    <Pagination
                      page={pageNumber}
                      setPage={v => onPageChange(v)}
                      totalPages={totalPage}
                      scrollRef={scrollRef}
                    />
                  </div>
                </div>
                <ProductsSideBarFilter
                  open={filterOpen}
                  setOpen={setFilterOpen}
                  setFilterBody={setFilterBody}
                  filterBody={filterBody}
                  toggleSidebar={() => {
                    setShowSideBar(true)
                    setFilterOpen(false)
                  }}
                  fromPage={true}
                  onFilterBodyChange={onFilterBodyChange}
                />
              </section>
            </main>
          </div>
        </div>
      </LayoutPage>
    </div>
  )
}

// export const query = graphql`
//   query allProducts {
//     allProductsListing {
//       nodes {
//         title
//         categories
//         id
//         nid
//         img
//         brand_name
//         brand_logo
//         created_at
//       }
//     }
//   }
// `
