import React, { Fragment, useContext, useState, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import DataContext from "../../context/Data/DataContext"
import FilterSelect from "./FilterSelect"
import FilterCheckbox from "./FilterCheckBox"
import FilterTag from "./FilterTag"
import Separator from "./Separator"
import FilterToggle from "./FilterToggle"
import StateContext from "../../context/state/StateContext"
import Icon from "../../assets/icons/close-alt.svg"
import { getTagCategoryProduct as getTagCategory } from "../../helpers/DrupalHelper"
import Slideout from "./Slideout"

const sortOptions = [
  { label: "Title (ascending)", value: { title: "desc" } },
  { label: "Title (descending)", value: { title: "asc" } },
  { label: "Price: Low to High", value: { field_product_price: "asc" } },
  { label: "Price: High to Low  ", value: { field_product_price: "desc" } },
]
export default function ProductsFilter({
  open,
  setOpen,
  setFilterBody,
  toggleSidebar,
  fromPage,
  onFilterBodyChange,
  setShowSideBar,
  showSideBar,
  className,
  filterBody,
}) {
  //   const [open, setOpen] = useState(true)
  const { tagsAsOptions: tags } = useContext(DataContext).data
  const { state } = useContext(StateContext)

  const [categories, setCategories] = useState([])
  const [pType, setPType] = useState([])
  useEffect(async () => {
    let catData = await getTagCategory(state.token)
    // console.log(catData)
    let cData = catData.map(v => {
      return { value: v.id, label: v.title }
    })
    setCategories([...cData])
    // let ptData = await getTagProductType(state.token)
    // // console.log(ptData)
    // let pData = ptData.map(v => {
    //   return { value: v.id, label: v.title }
    // })
    // setPType([...pData])
  }, [])
  return (
    <form className={className}>
      {showSideBar && (
        <button
          type="button"
          className="flex mb-4 justify-center items-center bg-[#F7F7F7] rounded-[8px] h-[40px] w-[40px] ring-1 ring-offset-2 ring-transparent hover:ring-[#222] transition-all"
          onClick={() => {
            setShowSideBar(false)
            setOpen(true)
          }}
        >
          <span className="sr-only">Sidebar Off</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm7.707 3.293a1 1 0 010 1.414L9.414 9H17a1 1 0 110 2H9.414l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      )}
      {categories.length && (
        <FilterCheckbox
          title={"Category"}
          options={categories}
          value={filterBody["categories"]}
          onChange={v => {
            setFilterBody(body => {
              if (fromPage) onFilterBodyChange({ ...body, categories: v })
              return { ...body, categories: v }
            })
          }}
        />
      )}
      <Separator className="my-[30px]" />
      {tags.length && (
        <FilterTag
          title={"Tags"}
          options={tags}
          value={filterBody["tags"]}
          onChange={v => {
            setFilterBody(body => {
              if (fromPage) onFilterBodyChange({ ...body, tags: v })

              return { ...body, tags: v }
            })
          }}
        />
      )}
      {/*<Separator className="my-[30px]" />*/}

      {/*        <FilterToggle
          title={"Asset Type"}
          options={[
            { value: "digital", label: "Digital" },
            { value: "physical", label: "Physical" },
          ]}
          defaultValue={["digital", "physical"]}
          onChange={v => {
            setFilterBody(body => {
              if (fromPage)
                onFilterBodyChange({
                  ...body,
                  asset_type: v.length > 1 ? v : v[0],
                })

              return {
                ...body,
                asset_type: v.length > 1 ? v : v[0],
              }
            })
          }}
        />
        <Separator className="my-[30px]" /> */}
    </form>
  )
}
